import { Link, graphql, navigate } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import React from 'react';
import BodyClassName from 'react-body-classname';

import ArrowLeft from '../images/icon-arrow-left.svg';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';

const Place = ({ title, image }) => {
  // Note: No Fluid Option available in image.
  const imageUrl = image.url;
  const dataPlacesTitle = `/places/${title.toLowerCase()}`;

  return (
    <div className="cols watch_item-list">
      <Link to={dataPlacesTitle}>
        <div className="watch_item-figure media ratio_9-16">
          <div className="m_overlay" />
          <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${imageUrl})` }} />
          <div className="m_border" />
        </div>
        <span className="watch_item-title">{title}</span>
      </Link>
    </div>
  );
};

const PlacesPage = (props) => {
  const {
    data: {
      bgContact,
      allPrismicPlaces: { nodes: allPrismicPlaces },
    },
  } = props;

  return (
    <BodyClassName className="body-light page-read page-read--category">
      <DefaultLayoutComponent>
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner over_lpd-banner bgImage bg-pos-center-top"
            fluid={bgContact.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-lg pd-x-sm">
              <div className="pd-x-sm pb-container">
                <h3 className="pb-title">Places</h3>
              </div>
              <div className="breadcrumb pd-t-0 bc-bg-transparent pd-x-sm watch-bc">
                <div className="bc-holder" onClick={() => navigate('/watch')}>
                  <img src={ArrowLeft} alt="" />
                  <p>Back</p>
                </div>
              </div>
            </div>
          </BackgroundImage>
          <section className="category-container over_lpd-container bg-dark pd-t-0 pd-b-half">
            <div className="wrapper wrapper-lg pd-x-sm">
              <div className="cat_watch-list">
                <div className="cat_watch-row bible">
                  <div className="cat_watch-body">
                    <div className="cat_watch-body-list">
                      <div className="cat_watch-item">
                        <div className="watch_item-row columns-6 d-flex">
                          {allPrismicPlaces?.map((person) => {
                            const dataPlaceName = person?.data?.placeName[0]?.text;
                            const dataImage = person?.data?.image;

                            return <Place title={dataPlaceName} image={dataImage} />;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  query PlacesPageQuery {
    bgContact: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allPrismicPlaces(limit: 100, sort: { fields: data___place_name___text }) {
      nodes {
        data {
          placeName: place_name {
            text
          }
          image {
            url
          }
        }
      }
    }
  }
`;

export default PlacesPage;
